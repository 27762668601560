import React from "react"
import { FaPhone, FaWhatsapp } from "react-icons/fa"

const trim = s => s.replace(/[-\(\) ]/g, "")
const separateNumber = s => s.match(/(\(.*\))(.*)/)

const WhatsApp = ({ phone, className }) => (
  <a
    href={`https://wa.me/${trim(phone.number).replace(/8/, "7")}`}
    className={className}
    onClick={() => {
      window.ym(41154959, "reachGoal", phone.reachGoal)
      return true
    }}
  >
    <FaWhatsapp
      style={{
        fontSize: "0.8rem",
        paddingRight: "0.1rem",
        paddingBottom: "0.1rem",
        color: "#0d0",
      }}
    />
    {phone.number}
  </a>
)

const SeparatedPhone = ({separated}) => (
  <>
    <span className="first-num">8</span>
    <span className="second-num">{separated[1]}</span>
    <span className="third-num">{separated[2]}</span>
  </>
)

const Phone = ({ phone, className }) => (
  <a
    href={`tel:${trim(phone.number)}`}
    className={`${className} contact-link`}
    onClick={() => {
      window.ym(41154959, "reachGoal", phone.reachGoal)
      return true
    }}
  >
    <FaPhone
      style={{
        fontSize: "0.7rem",
        paddingRight: "0.1rem",
      }}
    />
   <SeparatedPhone separated={separateNumber(phone.number)} />
  </a>
)

const MobilePhone = ({ phone, className }) => (
  <a
    href={`tel:${trim(phone.number)}`}
    className={`${className} contact-link`}
    onClick={() => {
      window.ym(41154959, "reachGoal", phone.reachGoal)
      return true
    }}
  >
    <FaPhone
      style={{
        fontSize: "0.7rem",
        paddingRight: "0.1rem",
      }}
    />
   {phone.number}
  </a>
)

export default ({ phones, className }) => {
  return phones.map((phone, index) => {
    if (phone.type === "whatsapp") {
      return <WhatsApp phone={phone} className={className} key={index} />
    } else if (phone.type === "phone") {
      return <Phone phone={phone} className={className} key={index} />
    } else if (phone.type === "mobile-phone") {
      return <MobilePhone phone={phone} className={className} key={index} />
    }
  })
}
