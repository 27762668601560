import React from "react"
import {Navbar, Nav, Container} from "react-bootstrap"
import {Link} from "gatsby"

// import ContactButton from "./contactButton"

// import {FaEnvelope} from 'react-icons/fa';

export default ({location, menu}) => {
  return (<Navbar expand="lg" variant="dark" style={{
    backgroundColor: "#bbb",
    padding: "0px"
  }}>

    <Container>
      <div className="navbar-collapse navbar-expand" id="main-navbar">
        <Nav className="mr-auto">
          {menu.map((item, index) => {
            return (
              <li
                className={`nav-item${location.pathname.split('/')[1]=== item.url.split('/')[1] ? ` active` : ``}`}
                key={index}
              >
                <Link to={item.url} className="nav-link p-1 p-md-2 px-md-3">
                  {item.label}
                </Link>
              </li>
            )
          })}
        </Nav>

        {/* <ContactButton
            className="btn btn-dark d-none d-md-inline text-white"
            title="Форма обратной связи"
            location={location}
          >
            <FaEnvelope
              width="30"
              height="30"
              style={{
                fontSize: "0.9rem",
                paddingRight: "0.3rem",
                paddingBottom: "0.2rem"
              }}
            />
            <span className="d-none d-md-inline">Написать нам</span>
          </ContactButton> */}
      </div>
    </Container>
  </Navbar>)
}